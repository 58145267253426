* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.loginouter {
  
  position: relative;

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0,0,0,.5);
  }

}

.detail-container {

  .detail-container-fields {
      border-bottom: 1px solid #e8e8e8;
      font-family: "Roboto";
      font-size: 14px;

      label{
        float: left;
        width: 100px;
      }
  }

}